import React, { useLayoutEffect, useState } from "react";
import queryString from "query-string";
import { QuantacoLoader } from "./Components/QuantacoLoader/QuantacoLoader";

function App() {
  const [iframeUrl, setIframeUrl] = useState<string>();
  const parsedQueryStrings = queryString.parse(window.location.search) as any;

  useLayoutEffect(() => {
    setIframeUrl(parsedQueryStrings.url);
  }, [parsedQueryStrings]);

  if (!iframeUrl) {
    return <QuantacoLoader size={200} />;
  }

  return (
    <div style={{ height: "100vh" }}>
      <iframe
        title="app"
        src={iframeUrl}
        frameBorder={0}
        style={{ border: 0, width: "100%", height: "100%" }}
        allowFullScreen
      />
    </div>
  );
}

export default App;
