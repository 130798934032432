import React from "react";
import "./index.css";

export const QuantacoLoader = ({ size = 400 }: { size?: number }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        className="pulse"
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: "#fff",
          height: size,
          width: size,
        }}
      >
        <div className="logoText" style={{ fontSize: `${size / 1.5}px` }}>
          Q
        </div>
      </div>
    </div>
  );
};
